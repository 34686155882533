import './Home.css'

function Home() {

    return (
        <div id="home">
            <div id='content'>
                <div><h1>Welcome</h1></div>
                <img id="headshot" src="./images/Headshot.JPEG" width={300} alt='No Img'/>
            </div>
            <div>
                <p>
                    I am a third year software engineering student who is minoring in Aerospace.
                    I am interest in embedded programming and the emerging field of IOT, and
                    other low-level programming applications.
                    I currently have industry experience in Fullstack Web Development and am always seeking to improve my skillset
                    and expand my horizons
                </p>
                <br/>
                <p>
                    I am a Former intern at Arcurve, where I developed features for a Logistics Dashboard for a client.
                    I worked mainly with Angular + Typescript, SQL Server, and the .Net Framework. I am very comfortable with and
                    a big fan of C#!
                </p>
                <br/>
                <p>
                    Outside of school, I enjoy playing the piano, video games, rock climbing, and working out. I like to dabble
                    around with my Arduino kits and spare electronics, and I am currently working on a project to automate
                    my morning coffee!
                </p>
                <br/>
                <p>
                    If you are looking for a hard-working, dedicated, and passionate student to work for you this summer,
                    please feel free to contact me at my email listed on the sidebar.
                </p>
            </div>
        </div>
    )
}

export default Home;