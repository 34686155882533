import Cube from './Cube'
import { Link } from 'react-router-dom';
import './Navbar.css'

function Navbar() {


    return (
        <nav id="navbar">
          <ul>
        <li>
          <Link to='/home' className='link'>
          <h1>Home</h1>
          <Cube size={50}/>
          </Link>
        </li>
        <li>
          <Link to='/projects' className='link'>
          <h1>Projects</h1>
          <Cube size={50}/>
          </Link>
        </li>
        <li>
          <Link to='/contact' className='link'>
          <h1>Contact</h1>
          <Cube size={50}/>
          </Link>
        </li>
        <li>
          <Link to='/resume' className='link'>
          <h1>Resume</h1>
          <Cube size={50}/>
          </Link>
        </li>
        </ul>
      </nav>
    );
}

export default Navbar;