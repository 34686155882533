import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Layout from './Layout';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import NoPage from './pages/NoPage';
import Resume from './pages/Resume';

function App() {
  
  return (
    <div className="App">
      <header className="App-header">
        <title>Nicola Savino</title>
      </header>
    
      <BrowserRouter>
      <Routes>
        <Route path="/" element = {<Layout/>}>
          <Route path='/home' element={<Home />} />
          <Route path='/projects' element={<Projects/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/resume' element={<Resume />}/>
          <Route path='*' element={<NoPage />} />
        </Route>
      </Routes>
      </BrowserRouter>

    
    </div>
  );
}

export default App;
